import axios from "axios";
const apiUrl =  process.env.VUE_APP_API_URL || "https://api.smartfarm.ddc.sze.hu";  /*"http://localhost:5000";*/
const prefix = "v1"
const dataProvider = () => {
  (function () {
    let token = localStorage.getItem("JWT");
    //console.log(token);
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = null;
      /*if setting null does not remove `Authorization` header then try     
          delete axios.defaults.headers.common['Authorization'];
        */
    }
  })();const c = console.log;
  return { 
    //---------------------------------------LOGIN---------------------------------------------------------------
    login: async (email, password) => {
      let url = `${apiUrl}/${prefix}/user/login`;
      c(url);
      let data = await axios
        .post(url, {
          email: email,
          password: password,
        })
        .then(function (response) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.jwt;
          return response.data;
        })
        .catch(function (error) {
          //handle error
          return error;
        });
      return data;
    },
    checkAuth: async () => {
      let url = `${apiUrl}/${prefix}/checkAuth`;
      try {
        let response = await axios.get(url);

        return response.data;
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },

    ///-----------------------------------------DEVICE_LOGS------------------------------------------------------
    getDeviceLog: async () => {
      let url = `${apiUrl}/${prefix}/device_log`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    deleteDeviceLogById: async (id) => {
      let url = `${apiUrl}/${prefix}/device_log/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    ///-----------------------------------------USERS------------------------------------------------------
    getUserByEmail: async (email) => {
      let url = `${apiUrl}/${prefix}/userEmail/${email}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    getUsers: async () => {
      let url = `${apiUrl}/${prefix}/user`;
      //let data=await
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    getUserById: async (id) => {
      let url = `${apiUrl}/${prefix}/user/${id}`;
      try {
        let response = await axios.get(url);
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    addNewUser: async (name, email, phone_number, password) => {
      let url = `${apiUrl}/${prefix}/user`;
      try {
        let response = await axios.post(url, {
          name: name,
          email: email,
          phone_number: phone_number,
          password: password,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return error.response.data;
      }
    },
    deleteUserById: async (id) => {
      let url = `${apiUrl}/${prefix}/user/${id}`;
      //let data=await
      try {
        let response = await axios.delete(url);
        console.log(response);
        return response.data;
      } catch (error) {
        console.log(error);

        return [];
      }
    },
    updateUserById: async (name, phone_number, password, id, email) => {
      //console.log(id);
      let url = `${apiUrl}/${prefix}/user/${id}`;
      //let data=await
      try {
        let response = await axios.put(url, {
          name: name,
          phone_number: phone_number,
          email: email,
          password: password,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  }
  };
export default dataProvider;
