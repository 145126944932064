<template>
  <div class="container">
    <Mapbox
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :zoom="zoom"
      :center="center"
      id="map"
    />
    <div class="row" id="full">
      <div id="info">
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample1"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Drónirányító és -adatelemző képzés
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample1"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample1Label">
              Drónirányító és -adatelemző képzés
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            <br />
            A képzés alapvető célja, hogy a távérzékelési és képfeldolgozási
            ismeretek magas szintű oktatásával számos szakterület képviselői
            válnak alkalmassá arra, hogy a teljes képzés elvégzése után a
            szakemberek alap szakterületéhez kapcsolódó munkájukat a kor
            követelményeinek megfelelően magasabb színvonalon legyenek képesek
            elvégezni.
            <br />
            <div class="video-container">
              <video class="video-container" controls :src="videoPath"></video>
            </div>
            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample5"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Modern erőgépek és eszközök
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample5"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample5Label">
              Modern erőgépek és eszközök
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            NewHolland erőgépek (T6.145 AC + T7.315 AC) - GPS rendszer - XCN
            kijelző technológia Horsch Pronto 4 DC + Singular system Hídmérleg,
            pótkocsi, teleszkópos rakodógép
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
              <button class="btn btn-primary" @click="addModel3()" id="btn3d">
                Kompaktor
              </button>
              <button class="btn btn-primary" @click="addModel4()" id="btn3d">
                Vetőgép
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample2"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Növényvédelmi drónpilóta képzés HAMAROSAN!
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample2"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample2Label">
              Növényvédelmi drónpilóta képzés HAMAROSAN!
            </h5> 
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            A szakképesítés megszerzésével a növényvédelmi drónpilóta mezőgazdasági monitoringozást és növényvédelmi kezelést végez drónnal különböző mezőgazdasági kultúrákban és lakott területen lévő zöldfelületeken.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
              <button class="btn btn-primary" @click="droneModel()" id="btn3d">
                L10 drón
              </button>
            </div>
          </div>
        </div>

        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample3"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Precíziós mezőgazdasái szakmérnök képzés
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample3"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample3Label">
              Precíziós mezőgazdasái szakmérnök képzés
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            <div id="carousel1" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carousel1"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carousel1"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carousel1"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src="images/precizios1.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="images/precizios2.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="images/precizios3.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carousel1"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carousel1"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
            <br />
            A szakmérnök képzés segít az agrár szakembereknek átállni a
            precíziós mezőgazdaságra, digitális megoldásokra. A képzés elősegíti
            az átmenetet a konvencionális gazdálkodásból a precíziós
            gazdálkodásba a digitalizáció nyújtotta gyakorlati készségek
            fejlesztésével.
            <br />
            <br />
            <br />
            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="droneModel()" id="btn3d">
                L10 drón
              </button>
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>

        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample7"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Mezőgazdasági Mérnöki Bsc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample7"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample7Label">
              Mezőgazdasági Mérnöki Bsc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            A szak képzési programja arányos egyensúlyt kíván fenntartani a
            mezőgazdasági diszciplína különböző területei között lehetőséget
            biztosítva a hallgatóknak, hogy a gyakorlatban a mezőgazdasági
            tevékenység valamennyi területén eredményes munkát végezhessenek.
            Ezek az ismeretek lehetőséget biztosítanak a végzett hallgatóknak,
            hogy az agrárium széles területein alkotó módon kapcsolódhassanak be
            a termelési és fejlesztési folyamatokba. Az átfogó oktatás képessé
            teszi a hallgatókat a határterületeken történő eredményes
            munkavégzésre is. A képzés során fontosnak tartjuk az ok-okozati
            összefüggések felismerésén alapuló mérnöki szintetizáló képességek
            kifejlesztését, a hallgatók felkészítését a legújabb tudományos
            eredmények befogadására, alkalmazására, a termelőmunka ennek alapján
            tudományos igényű irányítására. Ezen kívül fontos hangsúlyozni, hogy
            ez élő tudomány, tehát olyanok számára is látókör szélesítő lehet a
            képzés, akik elsősorban az elméleti kutatás területén képzelik el a
            jövőjüket.

            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample8"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Állattenyésztő Mérnök Bsc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample8"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample8Label">
              Állattenyésztő Mérnök Bsc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Célunk olyan állattenyésztő mérnökök képzése, akik általános
            ismeretekkel rendelkeznek a természet- és gazdaságtudományok
            területén, szakmai ismeretekkel az állattudomány területén és
            speciális ismeretekkel az állatfajok tenyésztése és tartása
            vonatkozásában. Megszerzett tudásuk birtokában képesek a korszerű
            kis- és nagyüzemi állattenyésztő telepek munkafolyamatainak
            irányítására, valamint az újszerű tartási és takarmányozási
            technológiák alkalmazására és bevezetésére. Résztvevői lehetnek a
            regionális, országos és nemzetközi pályázatoknak. Nyelv- és szakmai
            ismereteik lehetővé teszik, hogy a képzés második szakaszában (MSc)
            folytathassák tanulmányaikat.Í
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample9"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Élelmiszermérnöki Bsc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample9"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample9Label">
              Élelmiszermérnöki Bsc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Olyan élelmiszermérnökök képzése, akik általános ismeretekkel
            rendelkeznek a biológia, a kémia, a fizika és műszaki
            alaptudományokban, speciális ismere- tekkel az
            élelmiszertudományban, szakmai alapismeretekkel az élelmiszerek
            előállítása és tartósítása, valamint az élelmiszertechnológia terén.
            A mérnökök képesek, az élelmiszeripari műveletek alapjainak
            birtokában az élelmiszeripari feldolgozás és tartósítás korszerű
            technológiai és biotechológiai eljárások alkalmazására,
            minőségbiztosítási rendszerek kiépítésében közreműködésre és annak
            irányítására. Alkalmasak Európai Uniós, országos és regionális
            pályázatok készítésére. Képesek továbbá idegen nyelvi
            kommunikációra. Szilárd, megalapozott ismeretekkel rendelkeznek a
            képzés második ciklusába történő továbblépéshez (MSc képzés).
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample10"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Környezetmérnöki Bsc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample10"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample10Label">
              Környezetmérnöki Bsc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Egyetemünk környezetmérnöki szakán a képzés alapgondolata és célja
            olyan szakemberek képzése, akik a komplex és multidiszciplináris
            szemléletmód elsajátításával képesek összetett rendszerek, ágazati,
            regionális tervek és programok teljes körű környezeti vizsgálatára,
            környezetkímélő műszaki eljárások, technológiák, korszerű mérő- és
            informatikai eszközök alkalmazására. Hallgatóink a
            környezet-gazdaság-társadalom kapcsolatrendszer összefüggéseinek
            felismerése, feltárása, megértése, valamint elemzési módszereinek
            elsajátítása után képesek lesznek bonyolult problémákat műszaki,
            környezetvédelmi, gazdasági és társadalmi oldalról egyaránt
            felismerni, elemezni, értékelni, megoldani. Ennek elérése érdekében
            a környezetmérnök képzés részeként természettudományi, gazdasági és
            humán ismeretek, mérnöki tudományok, környezetmenedzsment,
            környezetelemzés, környezetinformatika és környezeti elemek védelme
            tárgykörökben mélyítjük el a leendő környezetmérnökök tudását.
            Képzésünk során hangsúlyt fektetünk arra is, hogy tanulmányuk
            végeztével hallgatóink jó kommunikációs készséggel rendelkezzenek,
            alkalmasak legyenek a projektszemléletű problémamegoldásra és
            képesek legyenek a felmerülő feladatok innovatív megoldására.

            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample11"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Mezőgazdasági és Élelmiszeripari Gépészmérnöki Bsc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample11"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample11Label">
              Mezőgazdasági és Élelmiszeripari Gépészmérnöki Bsc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Olyan gépészmérnökök képzése, akik természettudományi, műszaki,
            gazda- sági és humán ismereteik birtokában képesek a gépészeti,
            műszaki és technológiai berendezések fejlesztési részfeladatainak
            megoldására, a termelés és gépüzemeltetés irányítási feladatainak
            végrehajtására, továbbá kellő mély- ségű elméleti ismeretekkel
            rendelkeznek a képzés második ciklusban (MSc) történő folytatásához.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample12"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Mezőgazdasági Vízgazdálkodási és Környezettechnológiai Mérnöki Bsc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample12"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample12Label">
              Mezőgazdasági Vízgazdálkodási és Környezettechnológiai Mérnöki
              Bsc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            • természettudományos ismeretek (alkalmazott hidrológia,
            klimatológia, hidrobiológia, vízkémia) <br />
            • mezőgazdasági ismeretek (öntözéses gazdálkodás, precíziós
            mezőgazdaság, hidrokultúrás rendszerek, aszálykezelés, talajfizika,
            birtoktervezés és birtokrendezés, hullámtéri gazdálkodás) <br />
            • vízgazdálkodási ismeretek (integrált vízgazdálkodás,
            belvízgazdálkodás, mezőgazdasági vízszolgáltató rendszerek,
            mezőgazdasági vízgazdálkodási információs rendszerek, vizes
            élőhelyek kezelése és hasznosítása, melioráció, hidraulika,
            mezőgazdasági vízgazdálkodási monitoring) <br />
            • műszaki ismeretek (hidrológiai térinformatika és távérzékelés,
            mezőgazdasági vízgazdálkodási tervezés és kivitelezés)<br />
            • gazdasági- és társadalomtudományi ismeretek (hidroökonómia,
            vízpolitika és vízjog)

            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample13"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Vidékfejlesztési Agrármérnöki Bsc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample13"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample13Label">
              Vidékfejlesztési Agrármérnöki Bsc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            A képzés célja olyan vidékfejlesztési agrármérnökök képzése, akik
            képesek a termeléssel, szolgáltatással, szaktanácsadással összefüggő
            tervezési, szervezési, irányítási és adminisztratív feladatok
            ellátására. Az elsajátított mezőgazdasági, ökonómiai,
            környezetvédelmi, marketing és menedzsment, terület- és
            vidékfejlesztési ismereteik birtokában, valamint a szakigazgatási
            feladat- és intézményrendszer ismeretében képesek a munkaerő-piaci
            elvárásoknak megfelelő szakmai munka végzésére. Rendelkeznek az
            agrár- és vidékfejlesztés európai uniós normák szerinti
            értelmezéséhez, vidékfejlesztési programok tervezéséhez,
            lebonyolításához szükséges szakismeretekkel. Felkészültek
            tanulmányaik mesterképzésben való folytatására.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample14"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Tanári (Agrármérnöktanár - Mezőgazdaság) Msc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample14"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample14Label">
              Tanári (Agrármérnöktanár - Mezőgazdaság) Msc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            A képzés célja olyan szakemberek - agrár-mérnöktanárok - képzése,
            akik versenyképes tudás birtokában átlátják a mezőgazdaság lényeges
            területeit, kellő elméleti és gyakorlati ismerettel rendelkeznek
            ahhoz, hogy ezt a közoktatásban (szakképző iskolák és technikumok),
            valamint az iskolarendszeren kívüli képzőhelyeken tovább tudják adni
            tudásukat, tapasztalataikat. A tanulmányok során mind a
            mezőgazdasági szaktantárgyak, mind pedig a pedagógiai tantárgyak
            elsajátítása lehetővé válik. A Szak előnye, hogy mindezen ismeretek
            egy oktatási rendszerben szerezhetők meg.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample15"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Állattenyésztő Mérnöki Msc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample15"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample15Label">
              Állattenyésztő Mérnöki Msc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            A képzés célja olyan okleveles állattenyésztő mérnökök képzése,
            akiktermészettudományos, agrár-műszaki, élelmiszergazdasági,
            termék-fel- dolgozási ésállattudományi ismereteik birtokában képesek
            az állatitermék-ter- melés, feldolgozás, értékesítés, szakmai
            feladatai megoldására, továbbá az állattenyésztés területén
            tervező,szintetizáló kutatási és innovációs tevékeny- ség végzésére.
            A végzettek alkalmasaktanulmányaik doktori képzés kereté- ben
            történő folytatására.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample16"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Élelmiszerbiztonsági és Minőségi Mérnöki Msc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample16"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample16Label">
              Élelmiszerbiztonsági és Minőségi Mérnöki Msc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Olyan élelmiszerbiztonsági és -minőségi mérnökök képzése, akik
            mikrobi- ológiai, molekuláris biológiai, toxikológiai, analitikai
            ismereteik birtokában hatékonyan tudják felügyelni és ellenőrizni a
            biztonságos és kiváló minőségű, az emberi táplálkozásra minden
            tekintetben alkalmas élelmiszer-előállítás folyamatát és
            forgalmazását. A mesterszakon elsajátított ismeretek alapján a
            végzettek alkalmasak tanulmányaik doktori (PhD) képzés keretében
            történő folytatására.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample17"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Környezetgazdálkodási Agrármérnöki Msc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample17"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample17Label">
              Környezetgazdálkodási Agrármérnöki Msc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Olyan környezetgazdálkodási agrármérnökök képzése, akik megszerzett
            ter- mészettudományi és környezettudományi ismereteik birtokában
            alkalmasak a szakterületüknek megfelelő termelési folyamatok,
            illetőleg minőségbiztosítási szolgáltatások irányítási feladatainak
            ellátására és azok felügyeletére, a me- zőgazdaság és a környezet
            széles értelmű kapcsolatrendszerének figyelem- bevételével.
            Alkalmasak vezetői, illetve kutatói munkakörök betöltésére, vala-
            mint tanulmányaik doktori képzés keretében történő folytatására.Í
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample18"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Növényorvosi Msc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample18"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample18Label">
              Növényorvosi Msc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Olyan okleveles növényorvosok képzése, akik ismerik a növények
            termeszté- sével és védelmével, az egészséges élelmiszerek és
            takarmányok előállításá- val és minőségük biztosításával kapcsolatos
            rendszabályokat, folyamatokat; továbbá a növények termesztésének
            kockázatait, károsítóit, a védelemhez használt növényvédő szerek,
            vegyületeinek hatásmechanizmusát, munka- és élelmiszerhigiénés,
            valamint munkavédelmi előírásait, azok környezeti és hu- mán
            vonatkozású összefüggéseit. Képesek az agrártudomány területén, a
            képzés során elsajátított mezőgazdasági, növényvédelmi,
            természettudományi, műszaki, társadalomtudományi és gazdasági
            alapismeretek szintetizáló alkalmazására. Cél továbbá a tudományos,
            doktori és szakirányú továbbkép- zésben történő részvételre.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample19"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Mezőgazdasági Biotechnológus Msc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample19"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample19Label">
              Mezőgazdasági Biotechnológus Msc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Olyan okleveles mezőgazdasági biotechnológus mérnökök képzése, akik
            felkészültek a biokémia, mikrobiológia, élettan, a klasszikus és
            molekuláris genetika és transzgénikus nemesítés, a növényi
            szaporításbiológia területén, valamint ismerik és alkalmazzák ezek
            laboratóriumi gyakorlatát. Elméleti tudásuk, vezetési-szervezési és
            kommunikációs ismereteik birtokában alkalmasak tervező-fejlesztő
            mérnöki, kutatói, illetve vezetői munkakörök betöltésére,
            tanulmányaik doktori képzés keretében történő folytatására.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample20"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Mezőgazdasági Vízgazdálkodáso Mérnöki Msc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample20"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample20Label">
              Mezőgazdasági Vízgazdálkodáso Mérnöki Msc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            • természettudományos ismeretek (alkalmazott hidrológia,
            klimatológia, hidrobiológia, vízkémia) <br />
            • mezőgazdasági ismeretek (öntözéses gazdálkodás, precíziós
            mezőgazdaság, hidrokultúrás rendszerek, aszálykezelés, talajfizika,
            birtoktervezés és birtokrendezés, hullámtéri gazdálkodás) <br />
            • vízgazdálkodási ismeretek (integrált vízgazdálkodás,
            belvízgazdálkodás, mezőgazdasági vízszolgáltató rendszerek,
            mezőgazdasági vízgazdálkodási információs rendszerek, vizes
            élőhelyek kezelése és hasznosítása, melioráció, hidraulika,
            mezőgazdasági vízgazdálkodási monitoring) <br />
            • műszaki ismeretek (hidrológiai térinformatika és távérzékelés,
            mezőgazdasági vízgazdálkodási tervezés és kivitelezés) <br />
            • gazdasági- és társadalomtudományi ismeretek (hidroökonómia,
            vízpolitika és vízjog)

            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample21"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Regionális és Környezeti Gazdaságtan Msc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample21"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample21Label">
              Regionális és Környezeti Gazdaságtan Msc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            A mesterszak a gazdaság és a társadalom térbeli szerveződésének
            elemzésével, tervezésével és menedzselésével foglalkozik, a
            leghaladóbb elméletek és módszerek alapján. A képzés kiemelt
            figyelmet fordít a jövőt alakító tényezők, tendenciák (pl. városok
            jelentőségének növekedése, autonóm járművek megjelenése, innovatív
            városmenedzsment megközelítések, üzleti modellek) területi és
            környezeti-fenntarthatósági hatásainak vizsgálatára. A kis létszámú
            évfolyamoknak köszönhetően a hallgatóknak lehetőségük nyílik a
            vezető oktatók elméleti és alkalmazott kutatási projektjeibe való
            bekapcsolódásra, ezáltal tudásuk gyarapítására és elmélyítésére,
            valamint szakmai kapcsolataik építésére. A végzett hallgatók
            tanulmányaikat a Széchenyi István Egyetem Regionális- és
            gazdaságtudományi Doktori Iskolájában folytathatják.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample22"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Vidékfejlesztési Agrármérnöki Msc.
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample22"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample22Label">
              Vidékfejlesztési Agrármérnöki Msc.
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Olyan vidékfejlesztő agrármérnökök képzése, akik megszerzett
            ismereteik birtokában az erőforrások optimális felhasználását
            biztosító termelő, elosztó és szabályozási, valamint a termeléssel,
            a szolgáltatásokkal összefüggő tervezési, szervezési és irányítási
            folyamatokat felügyelik. Alkalmasak tervező-fejlesztő mérnöki,
            kutatói, illetve vezetői munkakörök betöltésére, valamint
            tanulmányaik doktori képzés keretében történő folytatására.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample23"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Agrármérnöki Osztatlan Képzés
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample23"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample23Label">
              Agrármérnöki Osztatlan Képzés
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Az öt éves osztatlan agrármérnök képzés visszaállítja a teljes
            mezőgazdaság tudományt átfogó oktatást. A végzett szakemberek a
            teljes termelési lánc minden lépését megismerve a legkeresettebbek
            lesznek a munkaerőpiacon. Ehhez a hagyományos képzési palettát
            modern, információ-technológiai tárgyakkal is bővítjük a kor
            igényeinek megfelelően.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample24"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Mezőgazdasági FOSZK
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample24"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample24Label">
              Mezőgazdasági FOSZK
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            A felsőoktatási szakképzés célja olyan agrárszakemberek képzése,
            akik részt vesznek a mezőgazdasági termelési folyamat, a
            termék-előállítás technológiai változatainak kidolgozásában;
            tervezik, szervezik és irányítják a mezőgazdasági termelést végző
            kis- és középüzemek munkáját, a termék-előállítás folyamatát, a
            feldolgozást, értékesítést, közreműködnek a pénzügyi tervek
            összeállításában, a pénzügyi döntés-előkészítésben.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample25"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Ménesgazda FOSZK
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample25"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample25Label">
              Ménesgazda FOSZK
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            Olyan sokoldalú lovas szakemberek képzése a cél, akik képesek
            nagyobb lovas létesítmények, ménesek telepvezetői, irányítói
            feladatait ellátni. Irányítják a tenyésztési munkát, párosítási
            terveket dolgoznak ki, koordinálják a telep életét, ismerve a
            minőségi tenyésztéshez szükséges takarmányozási és
            tartástechnológiai irányelveket, biztosítják a korszerű és piacképes
            lótartás feltételeit, valamint részt vesznek a lovak kiképzésében,
            tenyész-szemlékre, vizsgákra történő felkészítésében. Ismerik a
            lovak legfontosabb hasznosítási irányait, a ló- és lovassportok főbb
            szabályait, lényegét. Adott esetben részt vesznek lovas rendezvények
            szervezésében, lebonyolításában. A szükséges gazdasági, pénzügyi,
            jogi ismeretek és informatikai tudás birtokában részt vesznek a
            létesítmény gazdasági életének irányításában, valamint az admi-
            nisztratív munkában.
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>

        <button
          class="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample26"
          aria-controls="offcanvasScrolling"
          @click="addPolygonRombolo()"
        >
          Szakirányú Továbbképzések
        </button>

        <div
          class="offcanvas offcanvas-start"
          tabindex="-1"
          id="offcanvasExample26"
          data-bs-backdrop="false"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExample26Label">
              Szakirányú Továbbképzések
            </h5>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="offcanvas-close"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div>
          <div class="offcanvas-body">
            <table class="table-transparent" id="info-table-education" >
              <thead></thead>
              <tbody>
                <tr>
                  <td>Agrárdigitalizációs szakember</td>
                </tr>
                <tr>
                  <td>Agrárdigitalizációs szakmérnök</td>
                </tr>
                <tr>
                  <td>Precízios mezőgazdasági szakmérnök</td>
                </tr>
                <tr>
                  <td>Precíziós mezőgazdasági szaktanácsadó</td>
                </tr>
                <tr>
                  <td>Drónirányító és -adatelemző</td>
                </tr>
                <tr>
                  <td>Növényvédelmi szakmérnök</td>
                </tr>
                <tr>
                  <td>Agrármarketing és kereskedelmi szakmérnök</td>
                </tr>
                <tr>
                  <td>Élelmiszeripari logisztikai folyamatmenedzser</td>
                </tr>
                <tr>
                  <td>Élelmezésszervező szakmenedzser</td>
                </tr>
                <tr>
                  <td>Élelmezésszervező szakmérnök</td>
                </tr>
                <tr>
                  <td>Élelmiszer-termékpálya szakmenedzser</td>
                </tr>
                <tr>
                  <td>Élelmiszer-termékpálya szakmérnök</td>
                </tr>
                <tr>
                  <td>Halászati szakmérnök</td>
                </tr>
                <tr>
                  <td>Ökológiai gazdálkodó</td>
                </tr>
                <tr>
                  <td>Sörfőző mester szakmérnök</td>
                </tr>
                <tr>
                  <td>Sörfőző mester szaktanácsadó</td>
                </tr>
                <tr>
                  <td>Tejipari szakmérnök</td>
                </tr>
                <tr>
                  <td>Tejipari szaktanácsadó</td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <br />

            3D modellek
            <div class="btn-row" style="text-align: center">
              <button class="btn btn-primary" @click="threeModel1()" id="btn3d">
                Főépület
              </button>
              <button class="btn btn-primary" @click="threeModel2()" id="btn3d">
                Műhely
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="model">
        <div id="title-div"><h1 id="title-text">Oktatás</h1></div>

        <div class="logos">
          <div class="ddc-logo"></div>
          <div class="sze-logo"></div>
        </div>
      </div>
    </div>

    <div class="modelView" id="modelContainer1" ref="canvasContainer">
      <button class="btn btn-secondary" id="back-button" @click="goBack()">
        <i class="fa-solid fa-arrow-left"></i>
      </button>
    </div>

    <div id="dummyBlack">
      <semipolar-spinner
        :animation-duration="2000"
        :size="65"
        color="#0177a3"
      />
    </div>
  </div>
</template>

<script>
import Mapbox from "vue-mapbox";
import { SemipolarSpinner } from "epic-spinners";
import MapboxGl from "mapbox-gl";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import * as THREE from "three";

import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";

//import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

export default {
  name: "MapComponent",
  components: {
    Mapbox,
    SemipolarSpinner,
  },
  data() {
    return {
      map: null,

      isRombolo: false,
      is6112: false,
      isK3Genezises: false,
      is6111: false,
      is612: false,
      is81K6: false,
      is82K6: false,
      is133Genezises: false,
      isTemetoi: false,

      videoPath: "images/dronvideo.mp4",
    };
  },
  async mounted() {
    this.map = new MapboxGl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/dark-v11",
      projection: "globe",
      accessToken:
        "pk.eyJ1IjoiY3NhYmluZW1ldGgiLCJhIjoiY2xlOGt3YjVmMGdkbjN2bmJ6cHk4ZWN6eCJ9.VwQ_9iKWgBNkt6lEs0vsvg",
      zoom: 3,
      center: [17.270323, 47.89035],
      antialias: true,
      pitch: 45,
      bearing: -18,
    });

    this.map.on("style.load", () => {
      // Insert the layer beneath any symbol layer.
      const layers = this.map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      this.map.addLayer(
        {
          id: "add-3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 15,
          paint: {
            "fill-extrusion-color": "#aaa",

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );
    });

    this.map.on("load", () => {
      this.map.addSource("my-data", {
        type: "geojson",
        data: "osszesitett01.geojson",
      });
      this.map.addLayer({
        id: "my-data-layer",
        type: "fill-extrusion",
        source: "my-data",
        paint: {
          "fill-extrusion-color": "#0177a3",
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 5,
        },
      });
      this.map.flyTo({ center: [17.27079467, 47.89138084], zoom: 15 });
    });

    var nav = new MapboxGl.NavigationControl();
    this.map.addControl(nav, "top-left");
  },

  methods: {
    goBack() {
      console.log("asd");
      this.$router.push("../buildingParts");

      location.reload();
    },

    checkPolygons() {
      if (this.isRombolo == true) {
        this.map.removeLayer("rombolo");
        this.map.removeLayer("outline");
        this.map.removeSource("rombolo");
        this.isRombolo = false;
      }
      if (this.is6112 == true) {
        this.map.removeLayer("outline");
        this.map.removeLayer("6112");
        this.map.removeSource("6112");
        this.is6112 = false;
      }
      if (this.isK3Genezises == true) {
        this.map.removeLayer("outline");
        this.map.removeLayer("genezises");
        this.map.removeSource("genezises");
        this.isK3Genezises = false;
      }
      if (this.is6111 == true) {
        this.map.removeLayer("outline");
        this.map.removeLayer("6111");
        this.map.removeSource("6111");
        this.is6111 = false;
      }
      if (this.is612 == true) {
        this.map.removeLayer("outline");
        this.map.removeLayer("612");
        this.map.removeSource("612");
        this.is612 = false;
      }
      if (this.is81K6 == true) {
        this.map.removeLayer("81K6");
        this.map.removeLayer("outline");
        this.map.removeSource("81K6");
        this.is81K6 = false;
      }
      if (this.is133Genezises == true) {
        this.map.removeLayer("outline");
        this.map.removeLayer("133");
        this.map.removeSource("133");
        this.is133Genezises = false;
      }
      if (this.is82K6 == true) {
        this.map.removeLayer("outline");
        this.map.removeLayer("82K6");
        this.map.removeSource("82K6");
        this.is82K6 = false;
      }
      if (this.isTemetoi == true) {
        this.map.removeLayer("outline");
        this.map.removeLayer("temetoi");
        this.map.removeSource("temetoi");
        this.isTemetoi = false;
      }
    },

    async threeModel1() {
      this.map.flyTo({ center: [17.27079467, 47.89138084], zoom: 20 });

      document.getElementById("dummyBlack").style.opacity = "1";
      document.getElementById("dummyBlack").style.zIndex = "9999";

      setTimeout(() => {
        document.getElementById("modelContainer1").style.opacity = "1";
        document.getElementById("modelContainer1").style.zIndex = "99999";

        setTimeout(() => {
          document.getElementById("dummyBlack").style.opacity = "0";
          document.getElementById("dummyBlack").style.zIndex = "0";
        }, 2000);
      }, 2000);

      // Set up the Three.js scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasContainer.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.zoom = true;

      camera.position.set(0, 10, 0);
      camera.rotation.y = Math.PI / 4;
      camera.lookAt(0, 0, 0);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("images/blur.jpg");

      scene.background = texture;

      // Add basic lighting to the scene
      const light = new THREE.PointLight(0xffffff, 1, 10000);
      light.position.set(0, 10, 1);
      scene.add(light);

      //const MTLLoader = require('three-mtl-loader');
      const mtlLoader = new MTLLoader();

      // Load the MTL file
      mtlLoader.load("models/simplified_farm.mtl", (materials) => {
        materials.preload();

        const loader = new OBJLoader();

        loader.setMaterials(materials);
        loader.load("models/simplified_farm.obj", (object) => {
          object.traverse((child) => {
            if (child.isMesh) {
              const geometry = child.geometry;
              geometry.computeVertexNormals();
            }
          });
          scene.add(object);
        });
      });
      camera.position.z = 1;

      // Render the scene
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
    },

    async threeModel2() {
      this.map.flyTo({ center: [17.27079467, 47.89138084], zoom: 20 });

      document.getElementById("dummyBlack").style.opacity = "1";
      document.getElementById("dummyBlack").style.zIndex = "9999";

      setTimeout(() => {
        document.getElementById("modelContainer1").style.opacity = "1";
        document.getElementById("modelContainer1").style.zIndex = "99999";

        setTimeout(() => {
          document.getElementById("dummyBlack").style.opacity = "0";
          document.getElementById("dummyBlack").style.zIndex = "0";
        }, 2000);
      }, 2000);

      // Set up the Three.js scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasContainer.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.zoom = true;

      camera.position.set(-53, -63, 199);
      camera.rotation.y = Math.PI;
      camera.lookAt(0, 10, 0);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("images/blur.jpg");

      scene.background = texture;

      const light = new THREE.PointLight(0xffffff, 1, 90000);
      light.position.set(0, 10, 1);
      scene.add(light);

      const light2 = new THREE.PointLight(0xffffff, 1, 90000);
      light.position.set(0, 1, 0);
      scene.add(light2);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(0, 1, 0);
      scene.add(directionalLight);

      const pointLight = new THREE.PointLight(0xffffff, 1, 100);
      pointLight.position.set(0, 5, 0);
      scene.add(pointLight);

      const spotLight = new THREE.SpotLight(
        0xffffff,
        1,
        100,
        Math.PI / 4,
        0.5,
        1
      );
      spotLight.position.set(0, 2, 5);
      scene.add(spotLight);

      const mtlLoader = new MTLLoader();

      // Load the MTL file
      mtlLoader.load("models/muhely.mtl", (materials) => {
        materials.preload();

        const loader = new OBJLoader();

        loader.setMaterials(materials);
        loader.load("models/muhely.obj", (object) => {
          object.traverse((child) => {
            if (child.isMesh) {
              const geometry = child.geometry;
              geometry.computeVertexNormals();
            }
          });
          scene.add(object);
        });
      });

      // Render the scene
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
    },

    async droneModel() {
      this.map.flyTo({ center: [17.27079467, 47.89138084], zoom: 20 });

      document.getElementById("dummyBlack").style.opacity = "1";
      document.getElementById("dummyBlack").style.zIndex = "9999";

      setTimeout(() => {
        document.getElementById("modelContainer1").style.opacity = "1";
        document.getElementById("modelContainer1").style.zIndex = "99999";

        setTimeout(() => {
          document.getElementById("dummyBlack").style.opacity = "0";
          document.getElementById("dummyBlack").style.zIndex = "0";
        }, 2000);
      }, 2000);

      // Set up the Three.js scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasContainer.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.zoom = true;

      camera.position.set(-35, -197, 2050);
      camera.rotation.y = Math.PI / 4;
      camera.lookAt(0, 0, 0);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("images/blur.jpg");

      scene.background = texture;

      // Add basic lighting to the scene
      const light = new THREE.PointLight(0xffffff, 1, 10000);
      light.position.set(0, 10, 1);
      scene.add(light);

      // Add basic lighting to the scene
      const light2 = new THREE.DirectionalLight(0xffffff, 1);
      light2.position.set(0, 1, 0);
      scene.add(light2);

      //const MTLLoader = require('three-mtl-loader');
      const mtlLoader = new MTLLoader();

      // Load the MTL file
      mtlLoader.load("models/simplified_farm.mtl", (materials) => {
        materials.preload();

        const loader = new OBJLoader();

        loader.setMaterials(materials);
        loader.load("models/drone.obj", (object) => {
          object.traverse((child) => {
            if (child.isMesh) {
              const geometry = child.geometry;
              geometry.computeVertexNormals();
            }
          });
          scene.add(object);
        });
      });
      camera.position.z = 1;

      // Render the scene
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);

      };
      animate();
    },

    async addModel3() {
      this.map.flyTo({ center: [17.27079467, 47.89138084], zoom: 20 });

      document.getElementById("dummyBlack").style.opacity = "1";
      document.getElementById("dummyBlack").style.zIndex = "9999";

      setTimeout(() => {
        document.getElementById("modelContainer1").style.opacity = "1";
        document.getElementById("modelContainer1").style.zIndex = "99999";

        setTimeout(() => {
          document.getElementById("dummyBlack").style.opacity = "0";
          document.getElementById("dummyBlack").style.zIndex = "0";
        }, 2000);
      }, 2000);

      // Set up the Three.js scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasContainer.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.zoom = true;

      camera.position.set(15, -250, 1000);
      camera.rotation.y = Math.PI / 4;
      camera.lookAt(0, 0, 0);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("images/blur.jpg");

      scene.background = texture;

      // Add basic lighting to the scene
      const light = new THREE.PointLight(0xffffff, 1, 10000);
      light.position.set(0, 10, 1);
      scene.add(light);

      // Add basic lighting to the scene
      const light2 = new THREE.DirectionalLight(0xffffff, 1);
      light2.position.set(0, -1, 0);
      scene.add(light2);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(0, 1, 0);
      scene.add(directionalLight);

      const pointLight = new THREE.PointLight(0xffffff, 1, 100);
      pointLight.position.set(0, 5, 0);
      scene.add(pointLight);

      //const MTLLoader = require('three-mtl-loader');
      const mtlLoader = new MTLLoader();

      // Load the MTL file
      mtlLoader.load("models/simple_kompaktor.mtl", (materials) => {
        materials.preload();

        const loader = new OBJLoader();

        loader.setMaterials(materials);
        loader.load("models/simple_kompaktor.obj", (object) => {
          object.traverse((child) => {
            if (child.isMesh) {
              const geometry = child.geometry;
              geometry.computeVertexNormals();
            }
          });
          scene.add(object);
        });
      });
      camera.position.z = 1;

      // Render the scene
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);

      };
      animate();

      this.scene = scene;
      this.camera = camera;
      this.renderer = renderer;
      this.controls = controls;
    },

    async addModel4() {
      this.map.flyTo({ center: [17.27079467, 47.89138084], zoom: 20 });

      document.getElementById("dummyBlack").style.opacity = "1";
      document.getElementById("dummyBlack").style.zIndex = "9999";

      setTimeout(() => {
        document.getElementById("modelContainer1").style.opacity = "1";
        document.getElementById("modelContainer1").style.zIndex = "99999";

        setTimeout(() => {
          document.getElementById("dummyBlack").style.opacity = "0";
          document.getElementById("dummyBlack").style.zIndex = "0";
        }, 2000);
      }, 2000);

      // Set up the Three.js scene, camera, and renderer
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );

      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.canvasContainer.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.zoom = true;

      camera.position.set(-35, -197, 2050);
      camera.rotation.y = Math.PI / 4;
      camera.lookAt(0, 0, 0);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load("images/blur.jpg");

      scene.background = texture;

      // Add basic lighting to the scene
      const light = new THREE.PointLight(0xffffff, 1, 10000);
      light.position.set(0, 10, 1);
      scene.add(light);

      // Add basic lighting to the scene
      const light2 = new THREE.DirectionalLight(0xffffff, 1);
      light2.position.set(0, -1, 0);
      scene.add(light2);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(0, 1, 0);
      scene.add(directionalLight);

      const pointLight = new THREE.PointLight(0xffffff, 1, 100);
      pointLight.position.set(0, 5, 0);
      scene.add(pointLight);

      //const MTLLoader = require('three-mtl-loader');
      const mtlLoader = new MTLLoader();

      // Load the MTL file
      mtlLoader.load("models/simple_horsch.mtl", (materials) => {
        materials.preload();

        const loader = new OBJLoader();

        loader.setMaterials(materials);
        loader.load("models/simple_horsch.obj", (object) => {
          object.traverse((child) => {
            if (child.isMesh) {
              const geometry = child.geometry;
              geometry.computeVertexNormals();
            }
          });
          scene.add(object);
        });
      });
      camera.position.z = 1;

      // Render the scene
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
    },

    addPolygonRombolo() {
      // 1 2 18
      this.map.flyTo({ center: [17.27079467, 47.89138084], zoom: 15 });

      this.checkPolygons();

      this.isRombolo = true;

      this.map.addSource("rombolo", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [17.27014065, 47.89207887],
                [17.27140588, 47.89264677],
                [17.27178342, 47.89209886],
                [17.27180397, 47.89196545],
                [17.27175249, 47.89187972],
                [17.27163402, 47.89175862],
                [17.27100498, 47.89145164],
                [17.27085329, 47.89138859],
                [17.27079467, 47.89138084],
                [17.27074372, 47.89138441],
                [17.27069931, 47.89139502],
                [17.27049959, 47.89160467],
                [17.27039771, 47.89173159],
                [17.27031919, 47.89185272],
                [17.27026266, 47.89194426],
                [17.27014065, 47.89207887],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "rombolo",
        type: "fill-extrusion",
        source: "rombolo", // reference the data source
        layout: {},
        paint: {
          "fill-extrusion-color": "#d3d920", // blue color fill
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "rombolo",
        layout: {},
        paint: {
          "line-color": "#d3d920",
          "line-width": 3,
        },
      });
    },

    addPolygon6112Lajtapart() {
      //3 4 5 6 8 20
      this.map.flyTo({ center: [17.24908104, 47.90042467], zoom: 15 });

      this.checkPolygons();

      this.is6112 = true;

      this.map.addSource("6112", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [17.25201098, 47.89928848],
                [17.25040403, 47.89889035],
                [17.25015243, 47.89927801],
                [17.24990434, 47.89962507],
                [17.24973636, 47.89983973],
                [17.24960865, 47.89998224],
                [17.24944771, 47.90013512],
                [17.24908104, 47.90042467],
                [17.24878089, 47.90062357],
                [17.24892515, 47.90069787],
                [17.2492822, 47.90084631],
                [17.24963149, 47.90097189],
                [17.24989479, 47.9010367],
                [17.2501894, 47.90114107],
                [17.25048375, 47.90127524],
                [17.25065495, 47.90134577],
                [17.25201098, 47.89928848],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "6112",
        type: "fill-extrusion",
        source: "6112", // reference the data source
        layout: {},
        paint: {
          "fill-extrusion-color": "#d3d920", // blue color fill
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "6112",
        layout: {},
        paint: {
          "line-color": "#d3d920",
          "line-width": 3,
        },
      });
    },

    addPolygonK3genezises() {
      //7 9
      this.map.flyTo({ center: [17.26450595, 47.89712499], zoom: 15 });

      this.checkPolygons();

      this.isK3Genezises = true;

      this.map.addSource("genezises", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [17.26458707, 47.89284876],
                [17.26454347, 47.89286925],
                [17.26447904, 47.89293783],
                [17.26406529, 47.89351017],
                [17.26516704, 47.89383549],
                [17.26471009, 47.8945103],
                [17.26359214, 47.89418525],
                [17.26301721, 47.89500044],
                [17.26261244, 47.89560231],
                [17.26205932, 47.89642552],
                [17.26152856, 47.89722405],
                [17.26201359, 47.89734891],
                [17.26253611, 47.89748908],
                [17.26301906, 47.89760594],
                [17.26339634, 47.89769377],
                [17.26383882, 47.89781526],
                [17.26400518, 47.89785316],
                [17.26450595, 47.89712499],
                [17.26494832, 47.89647295],
                [17.26556299, 47.89558281],
                [17.26625495, 47.89457657],
                [17.26622662, 47.89456555],
                [17.26621624, 47.89454171],
                [17.26623791, 47.89450983],
                [17.26628023, 47.89448858],
                [17.26632024, 47.8944733],
                [17.26677407, 47.89385179],
                [17.26696393, 47.89362002],
                [17.26640342, 47.89343911],
                [17.26579308, 47.89325343],
                [17.26536208, 47.89310272],
                [17.26496346, 47.89299143],
                [17.26477064, 47.89292947],
                [17.26458707, 47.89284876],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "genezises",
        type: "fill-extrusion",
        source: "genezises", // reference the data source
        layout: {},
        paint: {
          "fill-extrusion-color": "#d3d920", // blue color fill
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "genezises",
        layout: {},
        paint: {
          "line-color": "#d3d920",
          "line-width": 3,
        },
      });
    },

    addPolygon6111() {
      // 10
      this.map.flyTo({ center: [17.25176236, 47.89686904], zoom: 15 });

      this.checkPolygons();

      this.is6111 = true;

      this.map.addSource("6111", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [17.25040403, 47.89889035],
                [17.25201098, 47.89928848],
                [17.2534753, 47.8970667],
                [17.25184178, 47.89673059],
                [17.25176236, 47.89686904],
                [17.25161623, 47.89709917],
                [17.2512537, 47.89762394],
                [17.25062073, 47.89855646],
                [17.25040403, 47.89889035],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "6111",
        type: "fill-extrusion",
        source: "6111", // reference the data source
        layout: {},
        paint: {
          "fill-extrusion-color": "#d3d920", // blue color fill
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "6111",
        layout: {},
        paint: {
          "line-color": "#d3d920",
          "line-width": 3,
        },
      });
    },

    addPolygon612() {
      // 11 14
      this.map.flyTo({ center: [17.25217935, 47.89596356], zoom: 15 });

      this.checkPolygons();

      this.is612 = true;

      this.map.addSource("612", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [17.2534753, 47.8970667],
                [17.2547449, 47.89516045],
                [17.25376925, 47.89484168],
                [17.25332312, 47.8946761],
                [17.25294458, 47.8945597],
                [17.25280793, 47.89455212],
                [17.25270928, 47.89458015],
                [17.25217935, 47.89596356],
                [17.25206925, 47.89623746],
                [17.25197813, 47.89645145],
                [17.25190436, 47.89661148],
                [17.25184178, 47.89673059],
                [17.2534753, 47.8970667],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "612",
        type: "fill-extrusion",
        source: "612", // reference the data source
        layout: {},
        paint: {
          "fill-extrusion-color": "#d3d920", // blue color fill
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "612",
        layout: {},
        paint: {
          "line-color": "#d3d920",
          "line-width": 3,
        },
      });
    },

    addPolygon81K6() {
      // 12
      this.map.flyTo({ center: [17.25828522, 47.89305547], zoom: 15 });

      this.checkPolygons();

      this.is81K6 = true;

      this.map.addSource("81K6", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [17.25874632, 47.89218356],
                [17.2582089, 47.89300202],
                [17.25828522, 47.89305547],
                [17.25872809, 47.89237919],
                [17.25882881, 47.89221272],
                [17.25874632, 47.89218356],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "81K6",
        type: "fill-extrusion",
        source: "81K6", // reference the data source
        layout: {},
        paint: {
          "fill-extrusion-color": "#d3d920", // blue color fill
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "81K6",
        layout: {},
        paint: {
          "line-color": "#d3d920",
          "line-width": 3,
        },
      });
    },

    addPolygon133Genezis() {
      // 15 16
      this.map.flyTo({ center: [17.26482576, 47.89433969], zoom: 15 });

      this.checkPolygons();

      this.is133Genezises = true;

      this.map.addSource("133", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [17.26381886, 47.89386215],
                [17.26371755, 47.89401933],
                [17.26482576, 47.89433969],
                [17.26492833, 47.89418829],
                [17.26381886, 47.89386215],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "133",
        type: "fill-extrusion",
        source: "133", // reference the data source
        layout: {},
        paint: {
          "fill-extrusion-color": "#d3d920", // blue color fill
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "133",
        layout: {},
        paint: {
          "line-color": "#d3d920",
          "line-width": 3,
        },
      });
    },

    addPolygon82K6() {
      // 17
      this.map.flyTo({ center: [17.25433834, 47.89046475], zoom: 15 });

      this.checkPolygons();

      this.is82K6 = true;

      this.map.addSource("82K6", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [17.25699939, 47.8949662],
                [17.25768238, 47.89395056],
                [17.25828522, 47.89305547],
                [17.25872814, 47.8923792],
                [17.25877765, 47.89229727],
                [17.25872809, 47.89237919],
                [17.25828522, 47.89305547],
                [17.2582089, 47.89300202],
                [17.25874632, 47.89218356],
                [17.25882876, 47.8922127],
                [17.25882878, 47.89221266],
                [17.25874632, 47.89218356],
                [17.25739486, 47.89165368],
                [17.25602392, 47.89111744],
                [17.2546698, 47.89058444],
                [17.25433834, 47.89046475],
                [17.25429994, 47.89050317],
                [17.25417922, 47.89083386],
                [17.25375195, 47.89193068],
                [17.25332948, 47.89299882],
                [17.25299291, 47.89384584],
                [17.25283118, 47.89420093],
                [17.25294401, 47.89425255],
                [17.25310012, 47.89432548],
                [17.25334099, 47.8944241],
                [17.2543768, 47.89480731],
                [17.25510495, 47.89507156],
                [17.25572622, 47.89528382],
                [17.2559989, 47.89536046],
                [17.25621203, 47.89543255],
                [17.25658397, 47.89557582],
                [17.25699939, 47.8949662],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "82K6",
        type: "fill-extrusion",
        source: "82K6", // reference the data source
        layout: {},
        paint: {
          "fill-extrusion-color": "#d3d920", // blue color fill
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "82K6",
        layout: {},
        paint: {
          "line-color": "#d3d920",
          "line-width": 3,
        },
      });
    },

    addPolygon11Temetoi() {
      // 19
      this.map.flyTo({ center: [17.26704703, 47.89779616], zoom: 15 });

      this.checkPolygons();

      this.isTemetoi = true;

      this.map.addSource("temetoi", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline Maine.
            coordinates: [
              [
                [17.26706009, 47.89361854],
                [17.26700246, 47.89365154],
                [17.26688602, 47.89378639],
                [17.26664452, 47.89410177],
                [17.26629542, 47.89459257],
                [17.26560068, 47.89560543],
                [17.26485875, 47.89667946],
                [17.26406628, 47.89785629],
                [17.26466395, 47.89800424],
                [17.26517184, 47.89813661],
                [17.26590878, 47.8983178],
                [17.26659465, 47.89848449],
                [17.26704703, 47.89779616],
                [17.26763568, 47.89693785],
                [17.26818902, 47.89612867],
                [17.26884359, 47.89516802],
                [17.26932317, 47.89447577],
                [17.26936293, 47.89434683],
                [17.26925784, 47.89430722],
                [17.2684555, 47.89407348],
                [17.26778498, 47.89387462],
                [17.26756616, 47.8937979],
                [17.26727548, 47.89368682],
                [17.26706009, 47.89361854],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "temetoi",
        type: "fill-extrusion",
        source: "temetoi", // reference the data source
        layout: {},
        paint: {
          "fill-extrusion-color": "#d3d920", // blue color fill
          "fill-extrusion-opacity": 0.5,
          "fill-extrusion-height": 15,
        },
      });
      // Add a black outline around the polygon.
      this.map.addLayer({
        id: "outline",
        type: "line",
        source: "temetoi",
        layout: {},
        paint: {
          "line-color": "#d3d920",
          "line-width": 3,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, #232841 0%, #ffffff 100%);
}

.btn {
  color: white;
  z-index: 1000;
  border: 1px solid grey;
  margin: 10px;
  width: 400px;
  background-color: #272525;
}

.card {
  margin-top: 10px;
  position: relative;
  backdrop-filter: blur(5px);

  border-radius: 15px;

  font-family: myFirstFont;
  color: white;

  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  z-index: 1000;
}

.table-dark {
  background-color: rgba(255, 255, 255, 0.5);
}

th {
  border: 1px solid grey;
  padding: 5px;
}

td {
  border: 1px solid grey;
  padding: 5px;
}

.ddc-logo {
  background-image: url("../../public/ddc_logo.png");
  position: relative;
  width: 150px;
  height: 100px;
  right: -150px;
  top: 0;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1000;
}

.sze-logo {
  background-image: url("../../public/sze.png");
  position: relative;
  width: 150px;
  height: 100px;
  right: -350px;
  bottom: 75px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1000;
}

#back-button {
  position: absolute;
  width: auto;
  left: 10px;
  top: 10px;
  z-index: 100;
}

#up-button {
  position: absolute;
  width: auto;
  left: 10px;
  bottom: 10px;
  z-index: 100;
}

#down-button {
  position: absolute;
  width: auto;
  left: 60px;
  bottom: 10px;
  z-index: 100;
}

#left-button {
  position: absolute;
  width: auto;
  left: 110px;
  bottom: 10px;
  z-index: 100;
}

#right-button {
  position: absolute;
  width: auto;
  left: 160px;
  bottom: 10px;
  z-index: 100;
}

.logos {
  width: 700px;
  height: 90px;
  background-image: linear-gradient(
      to top left,
      white 0 50%,
      transparent 0% 50%
    ),
    linear-gradient(to top right, white 0 0%, transparent 0% 100%),
    linear-gradient(white 0 0%);
  background-size: 20% 100%, 20% 100%, 60% 100%;

  background-position: left top, right top, center top;
  background-repeat: no-repeat;

  position: absolute;
  right: -160px;
  bottom: 0px;

  z-index: 100;
}

#modelContainer1 {
  border: none;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  position: absolute;
  top: 0;
  overflow: hidden;
  opacity: 0;
  border: 0;
  transition: opacity 1s ease-in-out;
}

#offcanvas-close {
  width: 38px;
}

#info {
  width: 30vw;
  height: 100vh;
  position: relative;
  background-color: transparent;
  overflow-y: scroll;
  z-index: 100;
  left: 2vw;
}



#model {
  width: 70vw;
  height: 100vh;
  position: relative;
  background-color: transparent;
  overflow: hidden;
}

#full {
  width: 100vw;
  background: linear-gradient(180deg, #232841 0%, #ffffff 100%);
}

#title-div {
  width: 700px;
  height: 90px;
  background-image: linear-gradient(
      to top left,
      white 0 50%,
      transparent 50% 100%
    ),
    linear-gradient(to top right, white 0 0%, transparent 0% 100%),
    linear-gradient(white 0 100%);
  background-size: 20% 100%, 20% 100%, 60% 100%;
  background-position: left top, right top, center top;
  background-repeat: no-repeat;

  position: absolute;
  right: -160px;
  top: 10px;

  z-index: 1000;
}

@font-face {
  font-family: myFirstFont;
  src: url(../../public/Roboto-Light.ttf);
}

#title-text {
  font-family: myFirstFont;
  color: black;
  font-size: 70px;
  position: relative;
  top: 0px;
  right: 20px;
}

#collapse-button {
  width: 100%;
  text-align: left;
}

#map {
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

.offcanvas-backdrop {
  display: none;
}

#offcanvasExample1 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample2 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample3 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample4 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample5 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample6 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample7 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample8 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample9 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample10 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample11 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample12 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample13 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample14 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample15 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample16 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample17 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample18 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample19 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample20 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample21 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample22 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample23 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample24 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample25 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#offcanvasExample26 {
  width: 40vw;
  background-color: #272525;
  color: white;
}

#info-table-education{
  width: 38vw;
}

.video-container {
  max-width: 38vw;
}

#carousel1 {
  position: relative;
  height: 35vh;
  overflow: hidden;
  margin-top: 20px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #272525;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
}

#info::-webkit-scrollbar {
  width: 10px;
}

#info::-webkit-scrollbar-track {
  background-color: transparent;
}

#info::-webkit-scrollbar-thumb {
  background-color: grey;
}



canvas {
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#dummyBlack {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  border: 0;

  display: flex; /* or display: inline-flex; */
  justify-content: center;
  align-items: center;
}
</style>
