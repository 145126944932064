<template>
	<div class="image">
		<div class="login">
			<div class="loginContent"><img src="images/logo.jpg" class="rounded mx-auto d-block" alt="...">
				<input class="inputBoxLogin" id="emailInput" v-model="useremail" type="email" name="useremail"
					placeholder="Username" @keydown="isEnter()" />
				<div class="passwordLine">
					<input class="inputBoxLogin" id="passwordInput" placeholder=" Password" v-model="password"
						type="password" @keydown="isEnter()" />
					<button @click="showPassword('loginPassword')" class="eyeButton" id="loginPasswordButtonLogin">
						<i class="far fa-eye" id="eyeIconLogin"></i>
						<i class="far fa-eye-slash" id="slashedEyeLogin"></i>
					</button>
				</div>
				<button class="btn btn-secondary" type="submit" @click="login()" id="loginButton">
					Login
				</button>
			</div>
		</div>
	</div>
</template>
  
<script>
import App from "@/App.vue";
import dataProvider from "../utils/dataProvider.js";
const dp = dataProvider();

export default {
	data() {
		return {
			useremail: undefined,
			password: null,
			error: null,
			deviceCount: 0,
			domainCount: 0,
			userCount: 0,
			c: console.log,
		};
	},

	methods: {

		async login() {
			try {
				const loginStatus = await dp.login(this.useremail, this.password);

				if (loginStatus.message == "Successfull login!") {
					const user = await dp.getUserByEmail(this.useremail);
					localStorage.setItem("username", user.first_name);
					localStorage.setItem("JWT", loginStatus.jwt);
					App.user = localStorage.getItem("username");
					localStorage.setItem("userEmail", this.useremail);
					this.$router.push("/");
				} else { console.log(loginStatus);
					if (loginStatus.message.includes("500")) {
						//alert("Something went wrong");
						document.getElementById("emailInput").style.borderColor = "red";
						setTimeout(function () {
							document.getElementById("emailInput").style.borderColor = "grey";
						}, 3000);
						document.getElementById("passwordInput").style.borderColor = "red";
						setTimeout(function () {
							document.getElementById("passwordInput").style.borderColor = "grey";
						}, 3000);
						
					} else if (loginStatus.message === "Wrong Username or password.") {
						document.getElementById("emailInput").style.borderColor = "red";
						setTimeout(function () {
							document.getElementById("emailInput").style.borderColor = "grey";
						}, 3000);
						document.getElementById("passwordInput").style.borderColor = "red";
						setTimeout(function () {
							document.getElementById("passwordInput").style.borderColor = "grey";
						}, 3000);
						//alert("Wrong E-mail address or password");
					}
				}

			} catch (error) {
				console.log(error);
			}

		},



		isEnter() {
			if (event.keyCode == 13) {
				document.getElementById("loginButton").click();
			}
		},

		showPassword(key) {
			switch (key) {
				case "loginPassword":
					if (document.getElementById("passwordInput").type == "password") {
						document.getElementById("passwordInput").type = "text";
						document.getElementById("eyeIconLogin").style.display = "none";
						document.getElementById("slashedEyeLogin").style.display = "block";
					} else if (document.getElementById("passwordInput").type == "text") {
						document.getElementById("passwordInput").type = "password";
						document.getElementById("eyeIconLogin").style.display = "block";
						document.getElementById("slashedEyeLogin").style.display = "none";
					}
					break;
				case "password":
					if (
						document.getElementById("createPasswordInput").type == "password"
					) {
						document.getElementById("createPasswordInput").type = "text";
						document.getElementById("pwEyeLogin").style.display = "none";
						document.getElementById("pwSlashedEyeLogin").style.display = "block";
					} else if (
						document.getElementById("createPasswordInput").type == "text"
					) {
						document.getElementById("createPasswordInput").type = "password";
						document.getElementById("pwEyeLogin").style.display = "block";
						document.getElementById("pwSlashedEyeLogin").style.display = "none";
					}
					break;
				case "passwordAgain":
					if (
						document.getElementById("passwordAgainInput").type == "password"
					) {
						document.getElementById("passwordAgainInput").type = "text";
						document.getElementById("pwAgainEyeLogin").style.display = "none";
						document.getElementById("pwAgainSlashedEyeLogin").style.display = "block";
					} else if (
						document.getElementById("passwordAgainInput").type == "text"
					) {
						document.getElementById("passwordAgainInput").type = "password";
						document.getElementById("pwAgainEyeLogin").style.display = "block";
						document.getElementById("pwAgainSlashedEyeLogin").style.display = "none";
					}
					break;
				default:
					break;
			}
		},
	},
};
</script>
  
<style scoped>
@font-face {
	font-family: myFirstFont;
	src: url(../../public/Roboto-Light.ttf);
}

.image {
	background-image: url(../../public/images/loginbg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	min-height: 105.5vh;
	margin-top: -10vh;
}

#eyeIconLogin {
	bottom: 0px;
	position: relative;
}

#slashedEyeLogin {
	display: none;
	bottom: 0px;
	position: relative;
}

.eyeButton {
	background-color: rgba(0, 0, 0, 0);
	border: 0;
	color: #fff;
	float: right;
	right: 15%;
	margin-top: -7%;
	position: relative;
	z-index: 2;
	width: auto;
}

.login {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.47);
	border-radius: 25px;
	top: 25vh;
	color: white;
	margin: 0 auto;
	padding-top: 0;
	position: relative;
	z-index: 2;
	max-width: 400px;
	max-height: 300px;
	opacity: 0.85;
}

.inputBoxLogin {
	position: relative;
	padding-top: 2%;
	margin-top: 2%;
	margin-left: 15%;
	min-width: 280px;
	font-size: 20px;
	border-radius: 15px;
	border: solid;
	border-color: grey;
	display: block;
	background-color: #4A8376;
}

.inputBoxLogin:focus{
	outline: solid;
	outline-color: #4A8376;
}

::placeholder {
	color: #fff;
	padding-bottom: 30%;
	margin-left: 30%;
}

::placeholder {
	color: #fff;
	padding-bottom: 30%;
	margin-left: 30%;
}

.loginContent {
	padding: 4% 0 4% 0;
	background-color: #013522;
	border-radius: 25px;
	max-height: 300px;
}

#loginButton {
	margin-top: 4%;
	margin-left: 37.5%;
	padding-bottom: 2%;
	position: relative;
	background-color: #fff;
	border-radius: 16px;
	background-color: #006346;
	opacity: 10;
	height: 75px;
	max-width: 100px;
	max-height: 50px;
	min-height: 45px;
	font-size: 1rem;
	font-weight: bold;
	display: block;
	border: solid;
	border-color: grey;
	width: 120px;
	font-weight: 800;
}

#loginButton:hover {
	background-color: #4A8376;
	border-color: grey;
	color: #fff;
	font-size: 1.1rem;
}

@media (max-width: 520px) {

	.login {
	top: 18vh;
}
}

@media (max-height: 1100px) {

.login {
top: 5vh;
}
}
</style>