import { createRouter, createWebHistory } from "vue-router";
import buildingParts from "../components/BuildingParts.vue";
import EducationProjects from "../components/EducationProjects.vue";
import LoginPage from "../components/LoginPage.vue";
import dataProvider from "../utils/dataProvider.js";

const dp = dataProvider();

const routes = [
    {
        path: "/",
        name: buildingParts,
        component: () =>
        import("../components/BuildingParts.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/education",
        name: "EducationProjects",
        component: EducationProjects,
        meta: { requiresAuth: true },
    },
    {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
      //console.log(localStorage.getItem('JWT'))
      //console.log(await dp.checkAuth(localStorage.getItem('JWT')))
      if ( !await dp.checkAuth(localStorage.getItem('JWT'))) {
        next({
          path: "/login",
        });
      }
      else next()
    } else next();
  });
  

export default router;